/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import PlanComparisonMobile from './PlanComparisonMobile'
import PlanComparisonDesktop from './PlanComparisonDesktop'

const Component = () => (
  <Fragment>
    <PlanComparisonMobile sx={{ display: [null, null, 'none'] }} />
    <PlanComparisonDesktop sx={{ display: ['none', null, 'block'] }} />
  </Fragment>
)

export default Component
