/** @jsx jsx */
import { jsx } from 'theme-ui'
import PlanComparisonMobileItem from './PlanComparisonMobileItem'
import usePricingData from './use-pricing-data'

const ListItem = ({ children }) => (
  <li sx={{ scrollSnapAlign: 'center' }}>
    {children}
  </li>
)

const Component = ({ className = undefined }) => {
  const pricing = usePricingData()
  const {
    freePlanBaseRequestsGb,
    freePlanStorageGb,
    paidPlanAdditionalRequestsPerTBStorageGb,
    paidPlanBaseRequestsGb,
    paidPlanAdditionalRequestsPackageGb,
    paidPlanAdditionalRequestsPackagePrice,
    paidPlanPricePerTbMonthly,
  } = pricing
  return (
    <div className={className}>
      <ul
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridAutoColumns: '100%',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          paddingX: 3,
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <ListItem>
          <PlanComparisonMobileItem
            baseRequestsGb={freePlanBaseRequestsGb}
            storageGb={freePlanStorageGb}
            title="FREE"
          />
        </ListItem>
        <ListItem>
          <PlanComparisonMobileItem
            additionalRequestsPackageGb={paidPlanAdditionalRequestsPackageGb}
            additionalRequestsPackagePrice={paidPlanAdditionalRequestsPackagePrice}
            additionalRequestsPerTBStorageGb={paidPlanAdditionalRequestsPerTBStorageGb}
            baseRequestsGb={paidPlanBaseRequestsGb}
            lightBackground
            pricePerTbMonthly={paidPlanPricePerTbMonthly}
            storageGb="unlimited"
            title="PAID"
          />
        </ListItem>
      </ul>
    </div>
  )
}
export default Component
