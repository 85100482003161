/** @jsx jsx */
import { jsx } from 'theme-ui'
import { shape, string } from 'prop-types'
import Link from '~/components/Link'

const DISPLAY_NAME = 'GetStartedLearnMoreLinks'

const PROP_TYPES = {
  getStarted: shape({
    href: string,
    to: string,
  }),
  learnMore: shape({
    href: string,
    to: string,
  }),
}

const Component = ({ getStarted, learnMore }) => (
  <div>
    {getStarted && (
      <div>
        <Link
          href={getStarted.href}
          to={getStarted.to}
          sx={{
            variant: 'buttons.primary',
            width: 'auto',
          }}
        >
          Get started
        </Link>
      </div>
    )}
    {learnMore && (
      <div sx={{ marginTop: 0.5 }}>
        <Link
          href={learnMore.href}
          to={learnMore.to}
          sx={{ variant: 'links.buttonLink' }}
        >
          Learn more
        </Link>
      </div>
    )}
  </div>
)

Component.propTypes = PROP_TYPES
Component.displayName = DISPLAY_NAME

export default Component
