/** @jsx jsx */
import { Fragment } from 'react'
import { bool, node, number, oneOfType, string } from 'prop-types'
import { jsx, Heading, Text } from 'theme-ui'

import formatPrice from '~/utilities/formatPrice'
import formatData from '~/utilities/formatData'
import NumeratorDenominator from '~/components/NumeratorDenominator'

import GetStartedLearnMoreLinks from '../GetStartedLearnMoreLinks'

const DISPLAY_NAME = 'PlanComparisonMobileItem'

const CELL_PADDING = 1
const CELL_MIN_HEIGHT = '115px'

const Cell = ({
  data,
  highlightData = false,
  lightBackground = false,
  subtext = undefined,
  title,
}) => (
  <div
    sx={{
      border: '1px solid',
      borderColor: 'text',
      padding: CELL_PADDING,
      minHeight: CELL_MIN_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: lightBackground && 'backgroundLightDisabled',
      ':first-of-type': {
        borderTopLeftRadius: 'large',
        borderTopRightRadius: 'large',
      },
      ':last-of-type': {
        borderBottomLeftRadius: 'large',
        borderBottomRightRadius: 'large',
      },
    }}
  >
    <div>
      <Heading
        as="h4"
        variant="prospectBody"
      >
        {title}
      </Heading>
      <Text
        as="div"
        variant="heading3"
        sx={{
          color: highlightData && 'brand',
          variant: 'text.heading3',
        }}
      >
        {data}
      </Text>
      <Text
        as="div"
        variant="finePrint"
      >
        {subtext}
      </Text>
    </div>
  </div>
)

Cell.propTypes = {
  data: oneOfType([string, node]).isRequired,
  highlightData: bool,
  lightBackground: bool,
  subtext: string,
  title: string.isRequired,
}

const zeroDollars = formatPrice(0, { showDecimals: false })
const mDash = (
  <Fragment>
    &mdash;
  </Fragment>
)

const PROP_TYPES = {
  additionalRequestsPackageGb: number,
  additionalRequestsPackagePrice: number,
  additionalRequestsPerTBStorageGb: number,
  baseRequestsGb: number.isRequired,
  lightBackground: bool,
  pricePerTbMonthly: number,
  storageGb: oneOfType([number, string]).isRequired,
  title: string.isRequired,
}

const Component = ({
  additionalRequestsPackageGb = undefined,
  additionalRequestsPackagePrice = undefined,
  additionalRequestsPerTBStorageGb = undefined,
  baseRequestsGb,
  lightBackground = false,
  pricePerTbMonthly = undefined,
  storageGb,
  title,
}) => {
  const unlimitedStorage = storageGb === 'unlimited'
  return (
    <div sx={{ paddingX: 1 }}>
      <Heading
        as="h3"
        variant="heading3"
      >
        {title}
      </Heading>
      <div sx={{ marginY: 2 }}>
        <Cell
          data={unlimitedStorage ? 'UNLIMITED' : formatData(storageGb, 'gb')}
          highlightData={unlimitedStorage}
          lightBackground={lightBackground}
          title="Storage"
        />
        <Cell
          title="Price"
          lightBackground={lightBackground}
          data={pricePerTbMonthly ? (
            <NumeratorDenominator
              numerator={formatPrice(pricePerTbMonthly)}
              denominator="TB"
            />
          ) : zeroDollars}
        />
        <Cell
          title="Included retrievals"
          data={formatData(baseRequestsGb, 'gb')}
          lightBackground={lightBackground}
          subtext={
            additionalRequestsPerTBStorageGb && (
              `+ ${formatData(additionalRequestsPerTBStorageGb, 'gb')} per additional storage TB`
            )
          }
        />
        <Cell
          title="Additional retrievals"
          lightBackground={lightBackground}
          data={
            additionalRequestsPackagePrice ? formatPrice(additionalRequestsPackagePrice) : mDash
          }
          subtext={additionalRequestsPackageGb && (
            `per additional ${additionalRequestsPackageGb} GB`
          )}
        />
      </div>
      <GetStartedLearnMoreLinks
        getStarted={{ to: '/' }}
        learnMore={{ to: '/' }}
      />
    </div>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
