/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { string } from 'prop-types'

import formatPrice from '~/utilities/formatPrice'
import NumeratorDenominator from '~/components/NumeratorDenominator'
import usePricingData from './use-pricing-data'
import formatData from '~/utilities/formatData'

import GetStartedLearnMoreLinks from './GetStartedLearnMoreLinks'

const DISPLAY_NAME = 'PlanComparison'

const CELL_WIDTH = 240
const CELL_HEIGHT = 110
const CELL_PADDING = 0.5
const HEADING_FOOTER_PADDING = 2

const PlanTableHeader = ({ children }) => (
  <th
    scope="column"
    sx={{
      minWidth: CELL_WIDTH,
      paddingBottom: HEADING_FOOTER_PADDING,
      textAlign: 'center',
      variant: 'text.heading3',
      lineHeight: 1,
    }}
  >
    {children}
  </th>
)

const PlanTableFoot = ({ children, ...restProps }) => (
  <td
    sx={{
      minWidth: CELL_WIDTH,
      paddingTop: HEADING_FOOTER_PADDING,
      textAlign: 'center',
    }}
    {...restProps}
  >
    {children}
  </td>
)

const TableData = ({ children, subText = undefined, ...restProps }) => (
  <td
    sx={{
      width: CELL_WIDTH,
      height: CELL_HEIGHT,
      padding: CELL_PADDING,
      variant: 'text.heading3',
      textAlign: 'center',
      border: '1px solid',
      borderColor: 'text',
    }}
    {...restProps}
  >
    {children}
    {subText && (
      <Text
        as="div"
        variant="finePrint"
      >
        {subText}
      </Text>
    )}
  </td>
)

TableData.propTypes = {
  subText: string,
}

const AttributeTableHeader = ({ children = undefined, ...restProps }) => (
  <th
    scope="row"
    sx={{
      height: CELL_HEIGHT,
      paddingTop: CELL_PADDING,
      paddingBottom: CELL_PADDING,
      paddingRight: 3,
      textAlign: 'right',
      whiteSpace: 'nowrap',
      variant: 'text.heading4',
    }}
    {...restProps}
  >
    {children}
  </th>
)

const Component = ({ className = undefined }) => {
  const pricing = usePricingData()
  const {
    freePlanBaseRequestsGb,
    freePlanStorageGb,
    paidPlanAdditionalRequestsPerTBStorageGb,
    paidPlanBaseRequestsGb,
    paidPlanAdditionalRequestsPackageGb,
    paidPlanAdditionalRequestsPackagePrice,
    paidPlanPricePerTbMonthly,
  } = pricing
  return (
    <div className={className}>
      <table
        sx={{
          borderSpacing: 0,
          '> tbody': {
            '> tr:not(:last-of-type) > td': {
              borderBottom: 0,
            },
            '> tr:first-of-type': {
              '> td:first-of-type': {
                borderTopLeftRadius: 'large',
              },
              '> td:last-of-type': {
                borderTopRightRadius: 'large',
              },
            },
            '> tr:last-of-type': {
              '> td:first-of-type': {
                borderBottomLeftRadius: 'large',
              },
              '> td:last-of-type': {
                borderBottomRightRadius: 'large',
              },
            },
            '> tr > td:last-of-type': {
              borderLeft: 0,
              backgroundColor: 'backgroundLightDisabled',
            },
          },
        }}
      >
        <thead>
          <tr>
            <td />
            <PlanTableHeader>FREE</PlanTableHeader>
            <PlanTableHeader>PAID</PlanTableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <AttributeTableHeader>
              Storage
            </AttributeTableHeader>
            <TableData>
              {formatData(freePlanStorageGb, 'gb')}
            </TableData>
            <TableData sx={{ color: 'brand' }}>
              UNLIMITED
            </TableData>
          </tr>
          <tr>
            <AttributeTableHeader>
              Price
            </AttributeTableHeader>
            <TableData>
              {formatPrice(0, { showDecimals: false })}
            </TableData>
            <TableData>
              <NumeratorDenominator
                numerator={formatPrice(paidPlanPricePerTbMonthly)}
                denominator="TB"
              />
            </TableData>
          </tr>
          <tr>
            <AttributeTableHeader>
              Included retrievals
            </AttributeTableHeader>
            <TableData>
              {formatData(freePlanBaseRequestsGb, 'gb')}
            </TableData>
            <TableData
              subText={`+ ${formatData(paidPlanAdditionalRequestsPerTBStorageGb, 'gb')} per additional storage TB`}
            >
              {formatData(paidPlanBaseRequestsGb, 'gb')}
            </TableData>
          </tr>
          <tr>
            <AttributeTableHeader>
              Additional retrievals
            </AttributeTableHeader>
            <TableData>&mdash;</TableData>
            <TableData>
              <NumeratorDenominator
                numerator={formatPrice(paidPlanAdditionalRequestsPackagePrice)}
                denominator={formatData(paidPlanAdditionalRequestsPackageGb, 'gb')}
              />
            </TableData>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <AttributeTableHeader />
            <PlanTableFoot>
              <GetStartedLearnMoreLinks
                getStarted={{ to: '/' }}
                learnMore={{ to: '/' }}
              />
            </PlanTableFoot>
            <PlanTableFoot>
              <GetStartedLearnMoreLinks
                getStarted={{ to: '/' }}
                learnMore={{ to: '/' }}
              />
            </PlanTableFoot>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

Component.displayName = DISPLAY_NAME

export default Component
