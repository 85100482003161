import { graphql, useStaticQuery } from 'gatsby'

const QUERY = graphql`
  query {
    pricing: contentfulPricing {
      ...Pricing
    }
  }
`

const usePricingData = () => {
  const { pricing } = useStaticQuery(QUERY)
  return pricing
}

export default usePricingData
