/** @jsx jsx */
import { Container, jsx, Heading } from 'theme-ui'
import { string } from 'prop-types'
import PlanComparison from '~/components/PlanComparison'
import IntroTransition from '~/components/IntroTransition'
import renderRichText from '~/content/renderRichText'
import { ContentfulRichTextPropType } from '~/types'
import Section from '~/components/Section'

const DISPLAY_NAME = 'PlanComparisonSection'

const PROP_TYPES = {
  elementId: string,
  heading: string.isRequired,
  body: ContentfulRichTextPropType,
}

const Component = ({ elementId, heading, body }) => (
  <Section
    id={elementId}
    sx={{
      paddingY: 6,
      textAlign: 'center',
    }}
  >
    <IntroTransition>
      <Container variant="containerSmall">
        <Heading
          sx={{
            variant: 'text.heading1',
            marginBottom: 0.5,
          }}
        >
          {heading}
        </Heading>
        <div sx={{ marginBottom: 4 }}>
          {renderRichText(body, { style: 'prospect' })}
        </div>
      </Container>
      <Container sx={{ display: ['none', null, 'block'] }}>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PlanComparison />
        </div>
      </Container>
      <div sx={{ display: [null, null, 'none'] }}>
        <PlanComparison />
      </div>
    </IntroTransition>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
